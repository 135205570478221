// ------------------ 通用方法 ---------------------

// 日期格式化
export const formateTime = (date, format = 'YYYY-MM-DD hh:mm:ss') => {
    // format  Y:年 M:月 D:日 h:时 m:分 s: 秒  例如：YYYY-MM-DD hh:mm:ss
    if (typeof format !== 'string') {
        throw new Error('formateTime 的第二个参数必须是字符串')
    }
    const time = new Date(date);
    const timeObj = {
        y: String(time.getFullYear()),
        m: String(time.getMonth() + 1),
        d: String(time.getDate()),
        h: String(time.getHours()),
        min: String(time.getMinutes()),
        s: String(time.getSeconds())
    }
    const dateArr = format.split('')
    const fixPre = (i, key) => {
        const len = timeObj[key].length - 1
        if (len >= 0) {
            dateArr[i] = timeObj[key][len]
            timeObj[key] = timeObj[key].substr(0, len)
        } else {
            dateArr[i] = '0'
        }
    }
    for (let i = dateArr.length - 1; i >= 0; i--) {
        switch(dateArr[i]) {
            case 'Y':
                fixPre(i, 'y')
                break
            case 'M':
                fixPre(i, 'm')
                break
            case 'D':
                fixPre(i, 'd')
                break
            case 'h':
                fixPre(i, 'h')
                break
            case 'm':
                fixPre(i, 'min')
                break
            case 's':
                fixPre(i, 's')
                break
            default:
                break
        }
    }
    return dateArr.join('')
}

// 序列化参数 obj=%7B%22a%22:1,%22b%22:2%7D&arr[]=1&arr[]=2&arr[]=3
export const serializeParams = (data) => {
    let str = ''
    for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
            const item = data[key];
            if (item === undefined) continue
            if (typeof item === 'object') {
                str += `&${ key }=${ encodeURIComponent(JSON.stringify(item)) }`
            } else {
                str += `&${ key }=${ encodeURIComponent(item) }`
            }
        }
    }
    return str.substring(1)
}

// 获取url参数
export const getUrlParam = (key, url = location.href) => {
    const reg = new RegExp(`[?&]${ key }=([^&]*)(&|$)`, 'i')
    const result = url.match(reg)
    return result ? decodeURIComponent(result[1]) : null
}

// 设置cookie
export const setCookie = (name, value, day = 1, domain = location.hostname, path = '/') => {
    const exp = new Date()
    exp.setTime(exp.getTime() + day * 24 * 60 * 60 * 1000)
    document.cookie = `${ name }=${ encodeURIComponent(value) };expires=${ exp.toUTCString() };domain=${ domain };path=${ path }`
}

// 获取cookie
export const getCookie = (name) => {
    // '__root_domain_v=.hodogame.cn; _qddaz=QD.292932619933517'
    const reg = new RegExp(`(^| )${ name }=([^;]*)(;|$)`)
    const result = document.cookie.match(reg)
    return result ? decodeURIComponent(result[2]) : null
}

// 删除cookie
export const deleteCookie = (name, domain) => {
    const exp = new Date()
    document.cookie = `${ name }=;expires=${ exp.toUTCString() }${domain ? `;domain=${ domain }` : ''}`
}