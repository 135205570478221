import GameApi from './modules/game'
import NewsApi from './modules/news'
import DataApi from './modules/data'

const Apis = {
    ...GameApi,
    ...NewsApi,
    ...DataApi,
}

export default Apis