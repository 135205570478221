<template>
  <div class="container">
    <Loading v-if="isLoading" v-model="isLoading" />
    <Home v-show="!isLoading" :loading="isLoading" />
  </div>
  
</template>

<script>
import Home from './components/home'
import Loading from './components/loading'
import Apis from '@/apis'
import { getUrlParam } from '@/utils'
export default {
  name: 'App',
  components: {
    Home,
    Loading
  },
  data() {
    const loaded = sessionStorage.getItem('loaded')
    return {
      isLoading: !loaded,
    }
  },
  created() {
    this.addStatisticData()
  },
  methods: {
    // 统计访问数埋点
    addStatisticData() {
      // 访问统计
      const roleInfo = JSON.parse(localStorage.getItem('roleInfo'))
      const recordDate = localStorage.getItem('recordDate')
      const today = new Date().toLocaleDateString()
      if (today !== recordDate) {
        Apis.statistic({
          phone: roleInfo ? roleInfo.phone : '',
          platfrom: 1, // 0是移动端1是pc端
          type: 2, // 1.下载 2.浏览
          sms: getUrlParam('sms') === '1' ? 1 : 0, // 是否短信进入，1是 0否
          os: /i(phone|pad|pod)/i.test(navigator.userAgent) ? 2 : 1, // 1.安卓2.ios
        })
        localStorage.setItem('recordDate', today)
      }
      
    },
  }
}
</script>

<style lang="scss">
html, body, p, ul, li, dl, dt, dd, ol {
  margin: 0;
  padding: 0;
}
html, body, #app {
  height: 100%;
}
@font-face {
  font-family: 'Orbitron-Bold';
  src: url('@/assets/font/Orbitron-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
.container {
  width: 100%;
  height: 100%;
  min-width: 1230px;
  overflow: auto;
  margin:  0 auto;
  background-color: #19232d;
  background: url('@/assets/img/bg.jpg') center center no-repeat;
  background-size: 100% 100%;
  font-family: Geneva, Verdana, sans-serif, 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', '黑体';
}
.img-full {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.bg-video {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  position: absolute;
  > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
.clearfix {
  &::after, &::before {
    clear: both;
    display: block;
  }
}
.fade-enter-active {
  transition: all .6s ease-out;
}

.fade-leave-active {
  transition: all .6s ease-in;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
// animate.css
:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}
.animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.animate__animated.animate__infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
@-webkit-keyframes flash {
  from,
  50%,
  to {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}
@keyframes flash {
  from,
  50%,
  to {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}
.animate__flash {
  -webkit-animation-name: flash;
  animation-name: flash;
}
/* Fading entrances  */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.animate__fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.animate__fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
/* Fading exits */
@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
.animate__fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
.animate__fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}
</style>
