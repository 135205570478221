<template>
  <div v-if="!articleInfo" class="news">
    <div class="news-pic">
      <img class="news-img" :src="slidePicItem.img" :alt="slidePicItem.title" @click="getNewsDetail(slidePicItem.id)" />
    </div>
    <div class="news-box">
      <div class="news-tab">
        <ul>
          <li
            v-for="(item, idx) in tabList"
            :key="item.className"
            class="tab-item"
            :class="[item.className, (selectTab === idx ? 'active' : '') ]"
            :title="item.title"
            @click="handleTabClick(idx)"
          ></li>
        </ul>
      </div>
      <div class="news-list">
        <div v-if="!newsList.length">
          <p class="empty">没有找到分类数据</p>
        </div>
        <swiper
          v-show="newsList.length"
          ref="newsSlide"
          class="news-slide"
          :modules="modules"
          effect="fade"
          :fade-effect="{crossFade: true}"
          :pagination="{
            clickable: true,
          }"
          :navigation="true"
        >
          <swiper-slide v-for="(pageSlide, idx) in newsList" :key="idx">
            <ul>
              <li v-for="newsItem in pageSlide" :key="newsItem.id" class="news-item" @click="getNewsDetail(newsItem.id)">
                <span>{{ newsItem.title }}</span>
                <span>{{formateDate(newsItem.defined_time)}}</span>
              </li>
            </ul>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
   <!-- // 文章详情 -->
   <div v-else class="news article swiper-no-swiping">
    <div class="article-content">
      <div class="top">
        <div class="title">{{ articleInfo.title }}</div>
        <div class="subtiitle">
          <div class="categorize">{{ articleInfo.name }}</div>
          <div class="datatime">{{ formateDate(articleInfo.defined_time) }}</div>
        </div>
      </div>

      <div class="content" v-html="articleInfo.content"></div>
    </div>
    <span class="back" @click="closeArticle">
      <svg t="1702885181471" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5395" width="200" height="200"><path d="M557.312 513.248l265.28-263.904c12.544-12.48 12.608-32.704 0.128-45.248-12.512-12.576-32.704-12.608-45.248-0.128l-265.344 263.936-263.04-263.84C236.64 191.584 216.384 191.52 203.84 204 191.328 216.48 191.296 236.736 203.776 249.28l262.976 263.776L201.6 776.8c-12.544 12.48-12.608 32.704-0.128 45.248 6.24 6.272 14.464 9.44 22.688 9.44 8.16 0 16.32-3.104 22.56-9.312l265.216-263.808 265.44 266.24c6.24 6.272 14.432 9.408 22.656 9.408 8.192 0 16.352-3.136 22.592-9.344 12.512-12.48 12.544-32.704 0.064-45.248L557.312 513.248z" p-id="5396" fill="#2feeff"></path></svg>
    </span>
  </div>
</template>

<script>
import Apis from '@/apis'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Pagination, Navigation, EffectFade } from 'swiper/modules'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { formateTime } from '@/utils'

export default {
  name: 'NewsPage',
  components: {
    Swiper,
    SwiperSlide,
  },
  emits: ['setMousewheel'],
  props: {
    isActive: Boolean,
  },
  data() {
    this.tabList = [{
      title: '最新',
      className: 'zx',
    }, {
      title: '活动',
      className: 'hd',
    }, {
      title: '公告',
      className: 'gg',
    }, {
      title: '资讯',
      className: 'xw',
    },]
    return {
      selectTab: 0,
      selectNewsId: 0,
      allNews: [],
      modules: [Pagination, Navigation, EffectFade],
      articleInfo: null,
      isLoading: false,
    };
  },
  computed: {
    newsList() {
      const list = this.allNews.filter(item => item.title === this.tabList[this.selectTab].title)
      if (!list.length) {
        return []
      }
      // 选择分类的全部新闻
      const selectNews = list[0].contents
      const pageNewsList = []
      const total = Math.ceil(selectNews.length / 4)
      // 选择分类分页新闻
      for (let i = 0; i < total; i++) {
        pageNewsList.push(selectNews.slice(i*4, i*4+4))
      }
      return pageNewsList
    },
    slidePicItem() {
      const item = this.newsList[0] ? this.newsList[0][0] : {}
      return item || {}
    },
  },
  watch: {
    isActive(val) {
      if (val) {
        this.getNewsList()
      } else {
        this.articleInfo = null
      }
    }
  },
  mounted() {
    // this.getNewsList()
  },
  methods: {
    async getNewsList() {
      const isProd = process.env.VUE_APP_PROD_ORIGIN.indexOf(location.host) >= 0
      const game_id = isProd ? process.env.VUE_APP_PROD_GAME_ID :  process.env.VUE_APP_TEST_GAME_ID
      const res = await Apis.getArticles({ game_id })
      // console.log(res)
      this.allNews = res.data.data
    },
    async getNewsDetail(article_id) {
      if (this.isLoading) return
      this.isLoading = true
      const res = await Apis.getArticle({ article_id })
      this.isLoading = false
      this.articleInfo = res.data.data
      this.$emit('setMousewheel', false);
    },
    closeArticle() {
      this.$emit('setMousewheel', true);
      this.articleInfo = null
    },
    handleTabClick(idx) {
      this.selectTab = idx
      this.$refs.newsSlide.$el.swiper.slideTo(0)
    },
    formateDate(datetime) {
      return formateTime(datetime * 1000, 'YYYY-MM-DD')
    }
  },
};
</script>

<style lang="scss" scoped>
$mainColor: #32feff;
.news {
  display: flex;
  padding: 109px 40px 0 40px;
  box-sizing: border-box;
  align-items: center;
  font-size: 18px;
  ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}
.news-pic {
  width: 60%;
  height: 507px;
  // background-color: rgba($color: #ffffff, $alpha: .1);
  flex: none;
  background: url('@/assets/img/news/mask_border.png') center right no-repeat;
  background-size: 100% 100%;
  .news-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    cursor: pointer;
    mask: url('@/assets/img/news/mask.png') center right no-repeat;
    mask-size: 100% 100%;
  }
}
.news-box {
  flex: none;
  width: 40%;
  min-width: 200px;
  height: 507px;
  padding-left: 50px;
  box-sizing: border-box;
}
.news-tab {
  background: url('@/assets/img/news/article_line.png') left bottom no-repeat;
  background-size: contain;
  padding-bottom: 35px;
  padding-top: 25px;
  > ul {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
li.tab-item {
  background-image: url('@/assets/img/news/news_tit.png');
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 160px 200px;
  display: inline-block;
  width: 74px;
  height: 29px;
  cursor: pointer;

  &.zx {
    background-position: 0 0;
  }
  &.hd {
    background-position: 0 -40px;
  }
  &.gg {
    background-position: 0 -80px;
  }
  &.xw {
    background-position: 0 -120px;
  }
  &.active {
    background-position-x: -80px;
  }
}
.news-list {
  padding-right: 20px;
  .news-slide {
    height: 410px;

    :deep(.swiper-slide-active) {
      position: relative;
      z-index: 2;
    }
  }
}
.empty {
  text-align: center;
  line-height: 2;
  padding-top: 50px;
  opacity: .6;
}
.news-item {
  position: relative;
  box-sizing: border-box;
  padding-right: 120px;
  line-height: 78px;
  height: 78px;
  border-bottom: 1px solid rgba($color: #ffffff, $alpha: .3);
  margin-bottom: 10px;
  display: flex;
  cursor: pointer;
  > span {
    &:first-child {
      flex: auto;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      min-width: 200px;
      width: 100%;
      &:hover {
        text-decoration: underline;
      }
    }
    &:last-child {
      flex: none;
      margin-left: 20px;
    }
  }
}
:deep(.swiper-pagination-bullets) {
  text-align: left;
  bottom: 20px;
  .swiper-pagination-bullet {
    background-color: rgba($color: #fff, $alpha: .5);
    width: 33px;
    height: 4px;
    border-radius: 0;
  }
  .swiper-pagination-bullet-active {
    background-color: $mainColor;
  }
}
:deep(.swiper-button-prev), :deep(.swiper-button-next) {
  width: 76px;
  height: 93px;
  bottom: -24px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  left: auto;
  top: auto;
  z-index: 11;
  &::after {
    content: none;
  }
  // position: absolute;
}
:deep(.swiper-button-prev) {
  right: 76px;
  background-image: url('@/assets/img/news/left.png');
  // clip-path: ;
}
:deep(.swiper-button-next) {
  right: 0px;
  background-image: url('@/assets/img/news/right.png');
}
.article {
  padding-top: 150px;
  padding-bottom: 120px;
  .back {
    position: absolute;
    top: 110px;
    right: 30px;
    cursor: pointer;
    .icon {
      width: 42px;
      height: 42px;
    }
  }
}
.article-content {
  width: 900px;
  padding: 0 20px;
  margin: 0 auto;
  height: 100%;
  overflow: auto;
  color: #c4e0e6;
  /* 设置滚动条颜色 */
  /* Webkit 内核浏览器 */
  &::-webkit-scrollbar {
    width: 7px; /* 设置滚动条宽度 */
    
  }
  /* 滚动条滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: #206e7c; /* 设置滑块颜色 */
    border-radius: 3px;
    overflow: hidden;
  }
  /* 滚动条滑块以外区域 */
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    overflow: hidden;
    background-color: rgba(255, 255, 255, .1); /* 设置滑块以外区域的背景颜色 */
  }
  .top {
    background: url('@/assets/img/news/line.png') left bottom no-repeat;
    background-size: contain;
    padding-bottom: 18px;
    margin-bottom: 28px;
    .title {
      font-size: 40px;
      line-height: 1.2;
      margin-bottom: 10px;
      text-align: center;
    }
    .subtiitle {
      font-size: 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .categorize {
        width: 66px;
        height: 33px;
        line-height: 33px;
        font-size: 15px;
        text-align: center;
        background: url('@/assets/img/news/categorize_bg.png') center center no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .content {
    font-size: 16px;
    line-height: 1.5;
    :deep(p) {
      margin-bottom: 10px;
    }
    :deep(img) {
      max-width: 100%;
      height: auto !important;
    }
  }
}
</style>