<template>
  <div class="slide-content sqlr">
    <div class="bg-video">
      <video
        preload="auto"
        autoplay="true"
        muted="true"
        loop="true"
        webkit-playsinline="true"
        playsinline="true"
        x5-playsinline="true"
        x-webkit-airplay="allow"
        poster="@/assets/img/index/video_poster.jpg"
      >
        <source src="@/assets/img/index/video.mp4" type="video/mp4">
      </video>
    </div> 
    
    <!-- <div class="info" @click="handleLogin">
      <img class="img-full" src="@/assets/img/index/info.png" alt="" />
    </div> -->
    <div class="reserve-count">
      <!-- <p class="tit">
        <span>已录入数字生命</span>
        <span>累计预约人数</span>
      </p> -->
      <p v-if="tweened.number === null" class="count">加载中</p>
      <p v-else class="count">{{Number(tweened.number.toFixed(0)).toLocaleString() }}<span>人</span></p>
    </div>
    <div class="txt">
      <div class="txt-img" :class="!isTxtRepeat ? 'txt-in' : 'txt-repeat'"></div>
      <!-- <img class="img-full" src="@/assets/img/index/slogan.gif" alt="" /> -->
    </div>
    <!-- <div class="play-btn animate__animated animate__pulse animate__infinite">
      <img class="img-full" src="@/assets/img/index/play_btn.png" alt="">
    </div> -->
    <!-- <div class="btn" @click="handleLogin">
      <div class="btn-img"></div>
    </div> -->
    <div class="btn_dl">
      <div class="qrcode">
        <template v-if="dlQrcode">
            <img class="img-full" :src="dlQrcode" alt="">
            <img class="qr_icon" src="@/assets/img/index/icon.jpg" alt="">
          </template>
      </div>
      <div class="btns">
        <a v-if="isSms" class="btn" target="_blank" href="https://fa.szkuniu.com/ios/?refer=300043.7329.13.1008" @click="downloadGame">
          <img class="img-full" src="@/assets/img/index/btn_store.png" alt="">
        </a>
        <a v-else class="btn" target="_blank" href="https://fa.szkuniu.com/ios/?refer=300043.7329.13.1006" @click="downloadGame">
          <img class="img-full" src="@/assets/img/index/btn_store.png" alt="">
        </a>
        <a class="btn" target="_blank" href="https://tap.cn/4ibeugeK?channel=rep-rep_1gvc2waalcc" @click="downloadGame">
          <img class="img-full" src="@/assets/img/index/btn_taptap.png" alt="">
        </a>
        <a v-if="isSms" class="btn" href="https://down.gzmywl.cn/apks/4cf2750b3cf0a888dfde7e5672ba8750.apk" @click="downloadGame">
          <img class="img-full" src="@/assets/img/index/btn_android.png" alt="">
        </a>
        <a v-else class="btn" href="https://down.gzmywl.cn/apks/300043_7238_27_com.jzlj.gzh_7_240528111920_aqPoo.apk" @click="downloadGame">
          <img class="img-full" src="@/assets/img/index/btn_android.png" alt="">
        </a>
        <a class="btn" href="https://lddl01.ldmnq.com/downloader/ldplayerinst9.exe?n=LDplayer9_com.jzlj.yxpp1_402505_ld.exe" @click="downloadGame">
          <img class="img-full" src="@/assets/img/index/btn_pc.png" alt="">
        </a>
      </div>
      <div class="age">
        <img class="img-full" src="@/assets/img/index/age.png" alt="16+">
      </div>
    </div>

    <!-- // 组队活动浮标入口 -->
    <div class="task-icon">
      <a href="/task" target="_blank">
        <img class="img-full" src="@/assets/img/icon_task.png" alt="">
      </a>
    </div>
  </div>
</template>
<script>
import gsap from 'gsap'
import { getUrlParam } from '@/utils'
import Apis from '@/apis';
import QRCode from 'qrcode';
export default {
  name: "ReserveFaEnterPage",
  emits: ['login'],
  props: {
    reserveCount: Number,
    loading: Boolean,
  },
  data() {
    return {
      isEntered: false,
      tweened: {
        number: null
      },
      isTxtRepeat: false,
      dlQrcode: '',
      isSms: false,
    };
  },
  watch: {
    reserveCount() {
      this.numberAnimate()
    },
    loading(state) {
      if (!state) {
        this.numberAnimate()
        setTimeout(() => {
          this.isTxtRepeat = true
        }, 1000);
      }
    }
  },
  mounted() {
    this.isSms = getUrlParam('sms') === '1' ? true : false
    this.numberAnimate()
    this.getDownloadQrcode()
  },
  methods: {
    getDownloadQrcode() {
      QRCode.toDataURL(location.origin + '/download/?sms=' + Number(this.isSms),  { errorCorrectionLevel: 'H', width: 102 }, (err, url) => {
        this.dlQrcode = url
      })
    },
    handleLogin() {
      this.$emit('login')
      if (/i(phone|pad|pod)/i.test(navigator.userAgent)) {
        this.iosCallback()
      }
    },
    // 下载游戏
    downloadGame() {
      // console.log(type)
      // console.log('download')
      const isDownload = localStorage.getItem('isDownload')
      if (isDownload) return
      localStorage.setItem('isDownload', 1)
      // 下载统计
      const roleInfo = JSON.parse(localStorage.getItem('roleInfo'))
      Apis.statistic({
        phone: roleInfo ? roleInfo.phone : '',
        platfrom: 1, // 0是移动端1是pc端
        type: 1, // 1.下载 2.浏览
        sms: Number(this.isSms), // 是否短信进入，1是 0否
        os: /i(phone|pad|pod)/i.test(navigator.userAgent) ? 2 : 1, // 1.安卓2.ios
      })
      if (/i(phone|pad|pod)/i.test(navigator.userAgent)) {
        this.iosCallback()
      }
    },
    numberAnimate() {
      if ((this.reserveCount || this.reserveCount === 0) && !this.loading) {
        gsap.to(this.tweened, {duration: 0.5, number: Number(this.reserveCount) || 0})
      }
    },
    iosCallback() {
      // 检测是否百度中打开
      if (getUrlParam("utm_source") === "adbdpz") {
        // &idfa={{IDFA}}
        // &os={{OS}}
        // &ip={{IP}}
        // &ua={{UA}}
        // &ts={{TS}}
        // &userid={{USER_ID}}
        // &pid={{PLAN_ID}}
        // &uid={{UNIT_ID}}
        // &aid={{IDEA_ID}}
        // &click_id={{CLICK_ID}}
        // &size={{SIZE}}
        // &callback_url={{CALLBACK_URL}}
        // &sign={{SIGN}}
        // &refer=300043.7329.2.1002
        const params = {
          platform: "baidu_ios",
          idfa: getUrlParam("idfa"),
          os: getUrlParam("os"),
          ip: getUrlParam("ip"),
          ua: getUrlParam("ua"),
          ts: getUrlParam("ts"),
          userid: getUrlParam("userid"),
          pid: getUrlParam("pid"),
          uid: getUrlParam("uid"),
          aid: getUrlParam("aid"),
          click_id: getUrlParam("click_id"),
          size: getUrlParam("size"),
          callback_url: getUrlParam("callback_url"),
          sign: getUrlParam("sign"),
          refer: '300043.7329.2.1002', // getUrlParam("refer"),
        };
        Apis.detection(params).then((res) => {
          console.log("--- 百度进入 ---", res);
        });
      } else {
        console.log("普通进入");
      }
    },
    // reserveFormate(num) {
    //   const numStr = num.toString()
    //   const len = numStr.lenght
      
    // }
  },
};
</script>

<style lang="scss" scoped>
$mainColor: #32feff;

// @keyframes btnAnimate {
//   @for $i from 0 through 25 {
//     #{$i * (100 / 26)}% {
//       background-position: #{-810 * $i}px 0;
//     }
//   }
// }
@keyframes sloganInAnimate {
  @for $i from 0 through 19 {
    
    #{$i * (100 / 20)}% {
      background-position: 0 #{-320 * $i}px;
      // @if ($i < 10) { background-image: url('@/assets/img/index/tit/tit_00#{$i}.png?sprite') }
      // @else if ($i < 100) { background-image: url('@/assets/img/index/tit/tit_0#{$i}.png?sprite') }
      // @else { background-image: url('@/assets/img/index/tit/tit_#{$i}.png?sprite') }
    }
  }
  100% {
    background-position: 0 -320 * 19px;
  }
}
// 123
@keyframes sloganRepeatAnimate {
  @for $i from 0 through 99 {
    #{$i * (100 / 100)}% {
      background-position: 0 #{-320 * ($i + 20)}px;
      // @if ($i < 10) { background-image: url('@/assets/img/index/tit/tit_00#{$i}.png?sprite') }
      // @else if ($i < 100) { background-image: url('@/assets/img/index/tit/tit_0#{$i}.png?sprite') }
      // @else { background-image: url('@/assets/img/index/tit/tit_#{$i}.png?sprite') }
    }
  }
}
.bg-video {
  &::after {
    content: '\20';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: url('@/assets/img/video_bg.png') left top repeat-x;
    background-size: auto 100%;
  }
}
.sqlr {
  height: 100%;
  .reserve-count {
    position: absolute;
    top: 126px;
    right: 0;
    width: 649px;
    height: 152px;
    z-index: 2;
    text-align: center;
    background: url('@/assets/img/index/reserve.png') center no-repeat;
    background-size: 100% 100%;
    .count {
      padding-top: 46px;
      padding-right: 45px;
      font-family: 'Orbitron-Bold';
      font-size: 80px;
      line-height: 1.3;
      letter-spacing: 6px;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image:-webkit-linear-gradient(90deg,#f2fdff,#d5fdff);
      white-space: nowrap;
      > span {
        font-size: 24px;
      }
    }
  }
  .play-btn {
    position: absolute;
    width: 118px;
    height: 118px;
    left: 50%;
    margin-left: -59px;
    top: 730px;
    z-index: 1;
    cursor: pointer;
  }
  
  .txt {
    position: absolute;
    width: 100%;
    bottom: 248px;
    height: 300px;
    z-index: 0;
    overflow: hidden;
    .txt-img {
      width: 1920px;
      height: 300px;
      left: 50%;
      transform: translateX(-50%);
      background: url('@/assets/img/index/tit_sprite.png') left top no-repeat;
      background-position: 0 -320 * 80px;
      background-size: 1920px 38390px;
      position: absolute;
      &.txt-in {
        animation: sloganInAnimate steps(1) .3s .5s both;
      }
      &.txt-repeat {
        animation: sloganRepeatAnimate steps(1) 1.8s infinite;
      }
    }
    
    
  }
  // .btn {
  //   position: absolute;
  //   bottom: 158px;
  //   width: 500px;
  //   height: 140px;
  //   left: 50%;
  //   margin-left: -250px;
  //   cursor: pointer;
  //   .btn-img {
  //     width: 800px;
  //     height: 300px;
  //     margin: -80px 0 0 -150px;
  //     background: url('@/assets/img/index/btn_sprite.png') left top no-repeat;
  //     background-position: 0 0;
  //     background-size: 21050px 300px;
  //     animation: btnAnimate 1s steps(1) infinite;
  //   }
  //   &:hover {
  //     transform: scale(1.05);
  //     transition: all ease .2s;
  //   }
  // }
  .btn_dl {
    display: flex;
    width: 583px;
    height: 160px;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 158px;
    left: 50%;
    margin-left: -292px;
    z-index: 2;
    background: url('@/assets/img/index/download_bg.png') center center no-repeat;
    background-size: 100% 100%;
    .qrcode {
      width: 102px;
      height: 102px;
      flex: none;
      border: 1px solid #8ff4fa;
      background: #fff url('@/assets/img/index/qr_code.png') center center no-repeat;
      position: relative;
      .qr_icon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 30px;
        height: 30px;
        margin-top: -15px;
        margin-left: -15px;
        z-index: 2;
        box-sizing: border-box;
        border-radius: 6px;
        overflow: hidden;
        border: 3px solid #fff;
      }
    }
    .btns {
      display: flex;
      flex: none;
      flex-wrap: wrap;
      gap: 6px;
      width: 316px;
      margin: 0 10px;
      .btn {
        width: 155px;
        height: 49px;
        display: block;
      }
    }
    .age {
      width: 81px;
      height: 104px;
    }
    
  }
  .info {
    position: absolute;
    top: 154px;
    width: 72px;
    height: 97px;
    right: 44px;
    // margin-left: -36px;
  }
}

@media (max-width: 1366px) {
  .sqlr {
    .reserve-count {
      width: 500px;
      height: 117px;
      .count {
        padding-right: 38px;
        padding-top: 38px;
        font-size: 58px;
        > span {
          font-size: 24px;
        }
      }
    }
    .txt {
      // width: 1065px;
      // height: 213px;
      bottom: 182px;
      // margin-left: -532px;
      // background-size: 1065px 27285.3px;
      transform: scale(0.71);
    }
    .btn_dl {
      transform: scale(0.8);
      bottom: 130px;
    }
    // .btn {
    //   bottom: 112px;
    //   width: 355px;
    //   margin-left: -177px;
    //   .btn-img {
    //     transform: scale(0.71);
    //     margin: -80px 0 0 -222.5px;
    //     // background-size: 14945.5px 213px;
    //   }
    //   // background-size: 568px 213px;
    // }
  }
  
}

.task-icon {
  width: 300px;
  height: 300px;
  position: fixed;
  top: 50%;
  margin-top: -150px;
  left: 50px;
  z-index: 8;
  @media (max-width: 1366px) {
    width: 200px;
    height: 200px;
    margin-top: -100px;
  }
}
</style>
