<template>
  <div class="yyjl">
    <div class="role">
      <img class="img-full" src="@/assets/img/award/role.gif" alt="">
    </div>
    <div class="content">
      <div class="tit">
        <img class="img-full" src="@/assets/img/award/title.png" alt="">
      </div>
      <div class="award">
        <div
          v-for="item in awardList"
          :key="item.num"
          class="award-item"
          :class="{ active: item.num < reserveCount }"
        >
          <img class="img-full" :src="item.img" alt="10w" />
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
export default {
  name: "ReserveFaAwardPage",
  props: {
    reserveCount: Number,
  },
  data() {
    this.awardList = [
      {
        num: 100000,
        img: require("@/assets/img/award/award_1.png"),
      },
      {
        num: 300000,
        img: require("@/assets/img/award/award_2.png"),
      },
      {
        num: 500000,
        img: require("@/assets/img/award/award_3.png"),
      },
      {
        num: 800000,
        img: require("@/assets/img/award/award_4.png"),
      },
      {
        num: 1000000,
        img: require("@/assets/img/award/award_5.png"),
      },
    ];
    return {}
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
$mainColor: #32feff;
.yyjl {
  // background: url('@/assets/img/award/page_bg.jpg') center center no-repeat;
  // background-size: cover;
  .role {
    width: auto;
    height: 100%;
    // padding-left: 163px;
    background: url('@/assets/img/award/role_bg.png') right 68px no-repeat;
    background-size: 100% 90%;
    position: absolute;
    right: 0;
    top: 0;
  }
  .content {
    position: absolute;
    width: 690px;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 225px 0;
    position: relative;
    z-index: 2;
    .tit {
      width: 333px;
      height: 106px;
      margin-bottom: 28px;
    }
  }
  
  
}
.award {
  display: flex;
  flex-wrap: wrap;
  .award-item {
    flex: none;
    width: 214px;
    height: 167px;
    margin: 0 7px;
    margin-top: 15px;
    position: relative;
    &:nth-child(4),
    &:nth-child(5) {
      width: 323px;
      height: 160px;
    }
    &::after {
      content: "\20";
      position: absolute;
      z-index: 2;
      top: -17px;
      right: -6px;
      width: 84px;
      height: 67px;
      background: url("@/assets/img/award/completed.png") center no-repeat;
      background-size: contain;
      display: none;
    }
    &.active::after {
      display: block;
    }
  }
}
@media (max-width: 1366px) {
  .yyjl {
    .content {
      transform-origin: 0% 0%;
      transform: scale(0.75) translateY(-50%);
      padding: 0px 181px 0;
    }
  }
}
</style>
