<template>
  <div class="drama">
    <div class="info">
      <div class="btns">
        <a class="btn" href="https://www.douyin.com/user/MS4wLjABAAAA53CDL2251ro8oHMtyUiWCVgRqVV7eAsXoCN77J0oorfA_JmSGsYb09ruCGXEstss" target="_blank">
          <img class="img-full" src="@/assets/img/drama/btn_link.png" alt="">
        </a>
        <div class="btn" @click="isShowDialog = true">
          <img class="img-full" src="@/assets/img/drama/btn_gift.png" alt="">
        </div>
      </div>
      <div class="tit">
        <img class="img-full" src="@/assets/img/drama/tit.png" alt="">
      </div>
    </div>

    <!-- 礼品弹框 -->
    <div v-if="isShowDialog" class="dialog">
      <div class="dialog-content gift animate__animated" :class="isShowDialog ? 'animate__fadeInUp' : 'animate__fadeOut'">
        <div class="code"><img class="img-full" src="@/assets/img/drama/txt.png" alt=""></div>
        <div class="gift-img"><img class="img-full" src="@/assets/img/drama/gift.png" alt=""></div>
        <div class="tips"><img class="img-full" src="@/assets/img/drama/tips.png" alt=""></div>
        <div class="btn-copy">
          <img class="img-full" src="@/assets/img/drama/btn_copy.png" alt="">
        </div>
        <div class="close" @click="handleClose"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import ClipboardJS from 'clipboard'
export default {
  name: "ShortDrama",
  props: {
    reserveCount: Number,
  },
  data() {
    return {
      isShowDialog: false,
      clipboard: null,
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.clipboard = new ClipboardJS('.btn-copy', {
        text: () => {
          return 'WXC520'
        }
      })
      this.clipboard.on('success', () => {
        ElMessage.success('复制成功')
      })
      this.clipboard.on('error', () => {
        ElMessage.success('您的浏览器不支持一键复制')
      })
    })
  },
  beforeUnmount() {
    this.clipboard.destroy()
  },
  methods: {
    // handleAfterLeave() {
    //   this.$emit("update:visit", false);
    // },
    handleClose() {
      this.isShowDialog = false;
      // setTimeout(() => {
      //   this.handleAfterLeave();
      // }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
$mainColor: #32feff;
.drama {
  background: url('@/assets/img/drama/bg.jpg') center 1% no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
}
.info {
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  .btns {
    display: flex;
    align-items: center;
    justify-content: center;
    .btn {
      display: block;
      width: 295px;
      height: 66px;
      margin: 0 20px;
      cursor: pointer;
    }
  }
  .tit {
    width: 1261px;
    height: 185px;
  }
}

.dialog {
  background-color: rgba($color: #000000, $alpha: 0.6);
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  .dialog-content {
    position: relative;
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    &.gift {
      width: 623px;
      height: 404px;
      background: url('@/assets/img/drama/gift_bg.png') center center no-repeat;
      background-size: 100% 100%;
      .code {
        width: 470px;
        margin: 80px auto 30px;
      }
      .tips {
        width: 275px;
        margin: 16px auto 20px;
      }
      .gift-img {
        width: 458px;
        margin: 0 auto;
      }
      .btn-copy {
        width: 205px;
        height: 53px;
        margin: 0 auto;
        cursor: pointer;
      }
    }
    .close {
      width: 49px;
      height: 49px;
      background: url('@/assets/img/dialog/dialog_close.png') center center no-repeat;
      background-size: 100% 100%;
      position: absolute;
      right: -42px;
      top: 34px;
      cursor: pointer;
    }
  }
}
@media (max-width: 1366px) {
  .info {
    .btns, .tit {
      transform: scale(0.8);
    }
  }
}
</style>
