// -------------------- 数据统计相关接口 api --------------------------
import axiosRequest from "../request"

// 百度搜索数据监测接口
export const detection = (data) => axiosRequest({
  url: 'https://tf-api.gzmywl.cn',
  method: 'GET',
  params: data,
})

export const statistic = (data) => axiosRequest({
  url: '/api/statistic',
  method: 'POST',
  data: data,
})

export default {
  detection,
  statistic,
}