<template>
  <div class="next-btn">
    <img class="img-full" src="@/assets/img/next.gif" alt="Next" />
  </div>
</template>
<script>
export default {
  name: "NextButton",
};
</script>

<style lang="scss" scoped>
@keyframes jumpAnimate {
  0% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}
.next-btn {
  width: 150px;
  height: 150px;
  position: absolute;
  bottom: -20px;
  left: 50%;
  margin-left: -75px;
  cursor: pointer;
  z-index: 2;
  // animation: jumpAnimate 1.3s ease-in-out infinite;
}
@media (max-width: 1366px) {
  .next-btn {
    width: 120px;
    height: 120px;
    margin-left: -60px;
  }
  
}
</style>
