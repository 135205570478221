<template>
  <div class="home animate__animated" :class="{animate__fadeIn: !loading}">
    <!-- 头部 -->
    <div class="head">
      <div class="head-left">
        <div class="brand">
          <img class="img-full" src="@/assets/img/logo.png" alt="logo" />
        </div>
        <!-- 菜单 -->
        <div class="menu">
          <ul>
            <li v-for="(item, idx) in menuList" :key="item" :class="{active: selectMenuIdx === idx}">
              <div :class="item" class="menu-item" @click="handleChageMenu(idx)"></div>
            </li>
          </ul>
        </div>
      </div>
      <div class="head-right">
        <bg-audio />
        <div v-if="!phone" class="user-info">您好，请<span class="link" @click="handleClickLogin">[登录]</span></div>
        <div v-else class="user-info">
          <img class="avator" src="@/assets/img/index/info.png" alt=""  @click="handleClickLogin" />
          <span class="link" @click="handleClickLogin">{{ phoneFormate(phone) }}</span>
          <span class="link" @click="logout">[退出]</span>
        </div>
      </div>
    </div>
    
    <!-- :lazy-preload-prev-next="true" -->
    <!-- :observer="true" -->
    <!-- :observer-parents="true" :auto-height="true" -->
    <swiper
      ref="slideElem"
      :autoplay="false"
      :mousewheel="true"
      :observer="true"
      :observe-parents="true"
      direction="vertical"
      slides-per-view="auto"
      class="slide-box"
      :modules="modules"
      @slide-change="handleSlideChange"
    >
      <swiper-slide class="slide-item">
        <enter-page :reserve-count="reserveCount" class="slide-content" :loading="loading" @login="handleClickLogin" />
        <next-button @click="handleNextBtnClick" />
      </swiper-slide>
      <swiper-slide class="slide-item">
        <short-drama-page class="slide-content" />
        <next-button @click="handleNextBtnClick" />
      </swiper-slide>
      <swiper-slide class="slide-item">
        <award-page :reserve-count="reserveCount" class="slide-content" />
        <next-button @click="handleNextBtnClick" />
      </swiper-slide>
      <swiper-slide class="slide-item">
        <news-page class="slide-content" :is-active="selectMenuIdx === 3" @setMousewheel="setMousewheel" />
        <next-button @click="handleNextBtnClick" />
      </swiper-slide>
      <swiper-slide class="slide-item">
        <world-page class="slide-content" :is-active="selectMenuIdx === 4" />
        <next-button @click="handleNextBtnClick" />
      </swiper-slide>
      <swiper-slide class="slide-item">
        <area-page class="slide-content" :is-active="selectMenuIdx === 5" />
        <next-button @click="handleNextBtnClick" />
      </swiper-slide>
      <swiper-slide class="slide-item footer-slide">
        <div class="footer">
          <div class="left">
            <div class="foot-icon">
              <ul>
                <li class="tap">
                  <a href="https://tap.cn/4ibeugeK?channel=rep-rep_1gvc2waalcc" target="_blank"><img class="img-full" src="@/assets/img/footer/tap.png" alt="taptap"></a>
                </li>
                <li class="dy">
                  <a href="https://v.douyin.com/iRowesxs/ 2@8.com" target="_blank"><img class="img-full" src="@/assets/img/footer/douyin.png" alt="抖音"></a>
                </li>
                <li class="bz">
                  <a href="https://space.bilibili.com/3546568416299444?spm_id_from=333.337.0.0" target="_blank"><img class="img-full" src="@/assets/img/footer/bilibili.png" alt="B站"></a>
                </li>
                <li class="wx" @click="showQrcode('wx')"><img class="img-full" src="@/assets/img/footer/weixin.png" alt="微信"></li>
                <li class="qq" @click="showQrcode('qq')"><img class="img-full" src="@/assets/img/footer/qq.png" alt="QQ群"></li>
              </ul>
              <img class="age" src="@/assets/img/footer/16+.png" title="公司游戏产品适合16岁（含）以上玩家娱乐" alt="适龄提示">
            </div>
            <p>抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防受骗上当</p>
            <p>适度游戏益脑 沉迷游戏伤身 合理安排时间 享受健康生活</p>
          </div>
          <div class="right">
            <p>公司游戏产品适合16岁（含）以上玩家娱乐</p>
            <p class="foot-link"><a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">粤ICP备10058939号-3</a>|<a href="https://pf-api.gzmywl.cn/privacy_kn.html" target="_blank">隐私政策</a>|<a href="https://pf-api.gzmywl.cn/privacy_kn.html" target="_blank">用户协议</a></p>
            <p>©深圳市酷牛互动科技有限公司</p>
            <p>深圳市前海深港合作区南山街道梦海大道5033号前海卓越金融中心3号楼L28-04</p>
          </div>
        </div> 
      </swiper-slide>
    </swiper>


    <!-- 登录弹框 -->
    <DialogComponent v-if="isShowDialog" v-model:visit="isShowDialog" @loginSuccess="loginSuccess"></DialogComponent>
    <!-- 二维码弹框 -->
    <div v-if="qrcodeType" class="preview">
      <div class="preview-body">
        <template v-if="qrcodeType === 'wx'">
          <img class="img" src="@/assets/img/wx_qrcode.jpg" alt="">
          <p class="img-tips">扫描二维码关注微信公众号</p>
        </template>
        <template v-else>
          <img class="img" src="@/assets/img/qq_group.png" alt="">
          <p class="img-tips">扫描二维码加入QQ群</p>
        </template>
        <span class="close" @click="closePreview"><svg t="1701826189826" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4328" width="40" height="40"><path d="M546.942134 511.818772l327.456957-326.128977c9.617355-9.577423 9.648071-25.135361 0.070648-34.751692-9.577423-9.617355-25.137409-9.647048-34.750668-0.070648L512.119795 477.137729 184.520518 150.868479c-9.616331-9.577423-25.176316-9.545683-34.751692 0.070648-9.577423 9.616331-9.545683 25.174268 0.070648 34.751692l327.457981 326.127953-327.457981 326.128978c-9.616331 9.577423-9.647048 25.135361-0.070648 34.751692a24.496456 24.496456 0 0 0 17.41117 7.231702 24.500552 24.500552 0 0 0 17.340522-7.162078L512.119795 546.499816l327.599276 326.26925a24.492361 24.492361 0 0 0 17.340522 7.162078 24.5026 24.5026 0 0 0 17.41117-7.231702c9.577423-9.617355 9.545683-25.175292-0.070648-34.751692L546.942134 511.818772z" fill="#ffffff" p-id="4329"></path></svg></span>
      </div>
    </div>

  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Mousewheel } from 'swiper/modules'
import BgAudio from './audio.vue'
import EnterPage from './enterPage.vue'
import ShortDramaPage from './shortDrama.vue'
import AwardPage from './awardPage.vue'
import WorldPage from './worldPage.vue'
import AreaPage from './areaPage.vue'
import NewsPage from './newsPage.vue'
import NextButton from "../nextButton"
import DialogComponent from '../dialog'
import Apis from '@/apis'
import { ElMessage } from 'element-plus'

export default {
  name: 'HomeCom',
  components: {
    EnterPage,
    AwardPage,
    WorldPage,
    AreaPage,
    NewsPage,
    Swiper,
    SwiperSlide,
    DialogComponent,
    NextButton,
    BgAudio,
    ShortDramaPage,
  },
  props: {
    loading: Boolean,
  },
  data() {
    this.menuList = ['home', 'drama', 'appoint', 'news', 'outlook', 'map']
    return {
      selectMenuIdx: 0,
      isShowMenu: false,
      isShowDialog: false,
      // swiper: null,
      // swiperHeigh: window.document.documentElement.clientHeight,
      reserveCount: null,
      phone: '',
      modules: [Mousewheel],
      qrcodeType: '',
      mousewheelOption: true,
    }
  },
  mounted() {
    this.getReserveCount()
    const roleInfo = JSON.parse(localStorage.getItem('roleInfo'))
    this.phone = roleInfo ? roleInfo.phone : ''
    // window.addEventListener('resize', () => {
    //   this.swiperHeigh = window.document.documentElement.clientHeight
    // })
  },
  methods: {
    async getReserveCount() {
      const res = await Apis.gettotal({})
      if (res.code) {
        return ElMessage.error(res.msg)
      }
      this.reserveCount = res.data.total
    },
    showMenu() {
      this.isShowMenu = true
    },
    handleClickLogin() {
      this.isShowDialog = true
    },
    handleNextBtnClick() {
      this.$refs.slideElem.$el.swiper.slideNext()
    },
    handleChageMenu(idx) {
      this.selectMenuIdx = idx
      this.$refs.slideElem.$el.swiper.slideTo(idx)
    },
    handleSlideChange({activeIndex}) {
      this.selectMenuIdx = activeIndex
      if (activeIndex !== 3) {
        this.setMousewheel(true)
      }
    },
    loginSuccess(phone) {
      this.getReserveCount()
      this.phone = phone
    },
    phoneFormate(phone) {
      return String(phone).replace(/^(\d{3})(\d{5})(\d{3})$/, '$1*****$3')
    },
    // 设置鼠标滚动事件
    setMousewheel(isOpen) {
      if (isOpen) {
        this.$refs.slideElem.$el.swiper.mousewheel.enable()
      } else {
        this.$refs.slideElem.$el.swiper.mousewheel.disable()
      }
    },
    logout() {
      this.phone = ''
      localStorage.removeItem('roleInfo')
    },
    showQrcode(type) {
      this.qrcodeType = type
    },
    closePreview() {
      this.qrcodeType = ''
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$mainColor: #32feff;
.home {
  height: 100%;
  overflow: hidden;
}
.head {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 1230px;
  height: 109px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  z-index: 8;
  padding: 0 35px 23px 10px;
  box-sizing: border-box;
  background: url('@/assets/img/menu_bg.png') left bottom no-repeat;
  .head-left {
    display: flex;
    align-items: center;
    .brand {
      width: 253px;
      padding-top: 20px;
      flex: none;
    }
  }
  
  .head-right {
    // font-size: 26px;
    display: flex;
    align-items: center;
    .user-info {
      // padding-right: 28px;
      color: #a2a5ac;
      font-size: 16px;
      text-shadow: 0 0 5px rgba($color: #000000, $alpha: .2);
      display: flex;
      align-items: center;
      .avator {
        width: 36px;
        height: 36px;
        display: block;
        cursor: pointer;
      }
    }
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-100px);
  opacity: 0;
}
.mark {
  background: rgba($color: #000000, $alpha: .6);
  z-index: 8;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.menu {
  padding-left: 72px;
  > ul {
    list-style: none;
    display: flex;
    > li {
      line-height: 86px;
      height: 86px;
      color: $mainColor;
      font-size: 34px;
      padding: 0 40px;
      
      .menu-item {
        height: 40px;
        margin-top: 30px;
        background-image: url('@/assets/img/menu_txt.png');
        background-repeat: no-repeat;
        background-size: 860px 85px;
        background-position: 0 0;
        cursor: pointer;
        &.home {
          width: 49px;
          background-position: 0 0;
        }
        &.drama {
          width: 77px;
          background-position: -660px 0
        }
        &.appoint {
          width: 129px;
          background-position: -132px 0;
        }
        &.outlook {
          width: 76px;
          background-position: -264px 0;
        }
        &.map {
          width: 131px;
          background-position: -396px 0;
        }
        &.news {
          width: 131px;
          background-position: -528px 0;
        }
      }
      &.active {
        background: url('@/assets/img/menu_select.png') left bottom repeat-x;
        .menu-item {
          background-position-y: -47px;
        }
      }
    }
  }
}

.slide-box {
  width: 100%;
  height: 100%;
  .slide-item {
    position: relative;
    color: #fff;
    height: 100%;
    &.footer-slide {
      height: auto;
    }
    .slide-content {
      position: relative;
      height: 100%;
      overflow: hidden;
      z-index: 1;
    }
  }
}

.link {
  margin-left: 10px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &:first-child {
    margin-left: 0px;
  }
}
.footer-slide {
  background-color: #232323;
}
.footer {
  padding: 50px 30px 35px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #aaa;
  max-width: 1300px;
  margin: 0 auto;
  .right {
    text-align: right;
    > p {
      line-height: 2;
      &.foot-link {
        > a {
          margin: 0 10px;
          &:last-child {
            margin-right: 0;
          }
        }
        a:link, a:visited {
          color: #aaa;
          text-decoration: none;
        }
        a:hover {
          color: #fff;
          text-decoration: underline;
        }
      }
    }
  }
  .left {
    text-align: left;
    > p {
      line-height: 2;
    }
    .foot-icon {
      border-top: 2px solid rgba($color: #fff, $alpha: .6);
      border-bottom: 2px solid rgba($color: #fff, $alpha: .6);
      display: inline-block;
      margin-bottom: 10px;
      position: relative;
      > ul {
        padding: 10px 10px;
        > li {
          display: inline-block;
          vertical-align: middle;
          margin-right: 40px;
          &.tap {
            width: 30px;
          }
          &.dy {
            width: 18px;
          }
          &.bz {
            width: 23px;
          }
          &.wx {
            width: 25px;
            cursor: pointer;
          }
          &.qq {
            width: 18px;
            cursor: pointer;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .age {
        position: absolute;
        width: 44px;
        height: auto;
        right: -56px;
        top: -6px;
      }
    }
  }
}
.preview {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: .7);
  z-index: 99;
  align-items: center;
  justify-content: center;
  .preview-body {
    background-color: #fff;
    padding: 10px;
    position: relative;
    .img {
      max-width: 100%;
      min-width: 260px
    }
    .img-tips {
      text-align: center;
      line-height: 1.2;
      padding-bottom: 20px;
      font-size: 18px;
    }
  }
  .close {
    font-size: 40px;
    color: #fff;
    position: absolute;
    top: 10px;
    right: -50px;
    z-index: 2;
    cursor: pointer;
    width: 40px;
    height: 40px;
    transform: rotate(0deg);
    transition: all .2s ease;
    &:hover {
      transform: rotate(180deg);
    }
  }

}
@media (max-width: 1600px) {
  .head {
    background-position: -80px bottom;
    padding: 0 10px 23px 0px;
    .head-right {
      .user-info {
        font-size: 14px;
      }
    }
    
  }
  .menu {
    > ul > li {
      padding: 0 30px;
    }
  }
}
@media (max-width: 1366px) {
  .head {
    background-position: -110px bottom;
    padding: 0 10px 23px 0px;
    .head-left {
      .brand {
        width: 230px;
      }
    }
    .head-right {
      .user-info {
        font-size: 12px;
      }
    }
    
  }
  .menu {
    padding-left: 24px;
    > ul > li {
      padding: 0 22px;
    }
  }
}
</style>
