import axios from 'axios'

import { serializeParams } from '@/utils'


// 根据域名判断是否正式环境
const isProd = process.env.VUE_APP_PROD_ORIGIN.indexOf(location.host) >= 0
// const loginPage = !isProd ? import.meta.env.VITE_APP_LOGIN_PAGE : import.meta.env.VITE_APP_PROD_LOGIN_PAGE
const axiosRequest = axios.create({
    // BaseUrl 自动拼接在url前面
    baseURL: process.env.VUE_APP_PROD_ORIGIN + process.env.VUE_APP_API,
    // 超时时间 30秒
    timeout: 30000,
    // 请求头
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    },
    transformRequest: [(data, headers) =>  {
        if (data) {
            const ContentType = headers['Content-Type']
            // 添加公共参数
            if (isProd) {
                data.game_id = process.env.VUE_APP_PROD_GAME_ID
                data.pf_game_id = process.env.VUE_APP_PROD_PF_GAME_ID
            } else {
                data.game_id = process.env.VUE_APP_TEST_GAME_ID
                data.pf_game_id = process.env.VUE_APP_TEST_PF_GAME_ID
            }
            if (/x-www-form-urlencoded/i.test(ContentType)) {
                return serializeParams(data)
            } else if (/multipart\/form-data/i.test(ContentType)) {
                const formData = new FormData();
                Object.keys(data).forEach(key => {
                    formData.append(key, data[key]);
                })
                return formData
            } else {
                return JSON.stringify(data)
            }
        }
    }],
    // // `onUploadProgress` 允许为上传处理进度事件
    // onUploadProgress: function (progressEvent) {
    //     // 对原生进度事件的处理
    // },

    // // `onDownloadProgress` 允许为下载处理进度事件
    // onDownloadProgress: function (progressEvent) {
    //     // 对原生进度事件的处理
    // },
})

// 请求拦截
axiosRequest.interceptors.request.use(config => {
    // const headers = config.headers
    // const token = getCookie('magicToken') || ''
    // if (!token) {
    //     ElMessage.error('请先登录')
    //     setTimeout(() => {
    //         location.replace(loginPage)
    //     }, 1000)
    //     throw Error('请先登录')
    // }
    // headers['magicToken'] = token
    return config
}, function(err) {
    return Promise.reject(err)
})

// 响应拦截
axiosRequest.interceptors.response.use((res) => {
    // if (res.data.code === '100109') {
    //     // 登录失效
    //     deleteCookie('magicToken')
    //     location.replace(loginPage)
    // } else if (res.data.code !== '2000') {
    //     ElMessage.error(res.data.message)
    // }
    return res.data
}, function(err) {
    return Promise.reject(err)
})

// 取消请求
const requestController = () => {
    const controller = new AbortController()
    return controller
}
// const controller = new AbortController();
// axios.get('/foo/bar', {
//    signal: controller.signal
// }).then(function(response) {
//    //...
// });
// // 取消请求
// controller.abort()

export { requestController }
export default axiosRequest
