<template>
  <div class="warpper">
    <div class="bg-video">
      <video
        v-if="rate <= 97"
        preload="auto"
        autoplay="true"
        muted="true"
        loop="true"
        webkit-playsinline="true"
        playsinline="true"
        x5-playsinline="true"
        x-webkit-airplay="allow"
        poster="@/assets/img/loading/loading_bg.jpg"
      >
        <source src="@/assets/img/loading/loading_video_1.mp4" type="video/mp4">
      </video>
      <video
        v-else
        preload="auto"
        autoplay="true"
        muted="true"
        webkit-playsinline="true"
        playsinline="true"
        x5-playsinline="true"
        x-webkit-airplay="allow"
        poster="@/assets/img/loading/loading_bg.jpg"
      >
        <source src="@/assets/img/loading/loading_video_2.mp4" type="video/mp4">
      </video>
      <!-- https://fa-test.szkuniu.com/media/bg.2a4f1a40.mp4 -->
    </div>
    <div class="content">
      <p>脑机接入中...</p>
      <div class="progress-box clearfix">
        <div class="progress">
          <div class="progress-bar" :style="{width: rate + '%'}"></div>
          <span class="progress-text">{{ tweened.number.toFixed(0) }}%</span>
        </div>
        <!-- <el-progress class="progress" :percentage="rate" color="#30efff"></el-progress> -->
        <!-- <div class="txt">{{ rate }}%</div> -->
      </div>
    </div>
    <div class="hide-element" >
      <img src="@/assets/img/index/tit_sprite.png" alt="">
      <img src="@/assets/img/index/btn_sprite.png" alt="">
      <!-- <div class="slogan"></div>
      <div class="btn"></div> -->
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'
export default {
  name: "LoadingCom",
  emits: ['update:modelValue'],
  // components: {
  //   ElProgress,
  // },
  data() {
    return {
      rate: 0,
      isLoaded: false,
      tweened: {
        number: 0
      },
    };
  },

  mounted() {
    window.addEventListener('load', this.handleLoaded.bind(this))
    this.timer2 = setTimeout(() => {
      this.handleLoaded()
    }, 3000)
    this.getProcessRate();
  },
  methods: {
    handleLoaded() {
      this.isLoaded = true
    },
    getProcessRate() {
      const sec = Math.floor(Math.random() * (1000 - 200 + 1) + 200);
      this.timer = setTimeout(() => {
        if (this.rate === 100) {
          clearTimeout(this.timer);
          this.timer = null;
          sessionStorage.setItem('loaded', 1)
          this.$emit('update:modelValue', false)
          return
        }
        this.rate += Math.floor(Math.random() * 30 + 1);
        this.rate = Math.min(this.rate, 97);
        if (this.rate === 97 && this.isLoaded) {
          this.rate = 100
          this.getProcessRate();
        } else {
          this.getProcessRate();
        }
        gsap.to(this.tweened, {duration: 0.5, number: Number(this.rate) || 0})
      }, sec);
    },
  },
  beforeUnmount() {
    clearTimeout(this.timer2);
    clearTimeout(this.timer);
    this.timer = null;
    window.removeEventListener('load', this.handleLoaded.bind(this))
  },
};
</script>

<style lang="scss" scoped>
.warpper {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.bg-video {
  &::after {
    content: '\20';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: url('@/assets/img/video_bg.png') left top repeat-x;
    background-size: auto 100%;
    opacity: .6;
  }
}
.content {
  position: absolute;
  z-index: 5;
  color: #fff;
  width: 459px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  > p {
    color: #fff;
    font-size: 26px;
    text-align: center;
    padding-bottom: 10px;
    font-weight: bold;
  }
  .progress-box {
    width: 459px;
    height: 84px;
    box-sizing: border-box;
    padding-top: 24px;
    background: url('@/assets/img/loading/loadbar_bg.png') left center no-repeat;
    background-size: 459px 84px;
    position: relative;
  }
  .progress {
    background-color: rgba($color: #fff, $alpha: 0.2);
    background: linear-gradient(48deg, transparent 4px, rgba($color: #fff, $alpha: 0.2) 0);
    height: 10px;
    margin: 0 10px;
  }
  .progress-text {
    color: #30efff;
    font-size: 16px;
    width: 80px;
    position: absolute;
    right: -76px;
    line-height: 20px;
    top: 18px;
  }
  .progress-bar {
    background-color: #30efff;
    background: linear-gradient(48deg, transparent 4px, #30efff 0);
    width: 0px;
    height: 100%;
    transition: width .2s linear;
  }
}
.hide-element {
  height: 0;
  overflow: hidden;
  display: none;
//   .slogan {
//     animation: sloganAnimate linear 2s infinite;
//   }
//   .btn {
//     animation: btnAnimate linear 1s 1s infinite;
//   }
}
</style>
