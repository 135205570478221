// -------------------- 游戏预约接口 api --------------------------
import axiosRequest from "../request"

// 预约
export const subscribe = (data) => axiosRequest({
    url: '/api/subscribe',
    method: 'POST',
    data
})
// 发送验证码
export const sendCode = (data) => axiosRequest({
    url: '/api/message/sendCode',
    method: 'POST',
    data
})
// 获取预约人数
export const gettotal = (data) => axiosRequest({
    url: '/api/gettotal',
    method: 'POST',
    data
})
// 添加角色名称
export const addRoleName = (data) => axiosRequest({
    url: '/api/addRoleName',
    method: 'POST',
    data
})
// 获取角色名称
export const getRoleName = (data) => axiosRequest({
    url: '/api/getRoleName',
    method: 'POST',
    data
})


export default {
    subscribe,
    sendCode,
    gettotal,
    addRoleName,
    getRoleName,
}