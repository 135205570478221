// -------------------- 新闻文章相关接口 api --------------------------
import axiosRequest from "../request"

// 获取新闻列表
export const getArticles = (data) => axiosRequest({
    url: '/api/getArticles',
    method: 'GET',
    params: data,
})
// 获取新闻详情
export const getArticle = (data) => axiosRequest({
    url: '/api/getArticle',
    method: 'GET',
    params: data,
})


export default {
    getArticles,
    getArticle,
}