<template>
  <div class="sjg">
    <swiper
      ref="worldSlide"
      class="world-slide"
      :height="760"
      :autoplay="{
        delay: 4000,
        disableOnInteraction: false,
      }"
      :lazy="true"
      :speed="600"
      :modules="modules"
      :parallax="true"
      @slide-change="handleSlideChange"
    >
      <swiper-slide v-for="(item, idx) in slideList" :key="item.name" class="slide-item">
        <div class="slide-img" data-swiper-parallax="-100">
          <img class="img-full" :src="item.pic" :alt="item.name" loading="lazy">
        </div>
        <div class="slide-info">
          <div class="tit" :class="`t${idx+1}`" data-swiper-parallax="-300">
            <img class="img-full" :src="item.title" :alt="item.name" loading="lazy">
          </div>
          <div class="line" data-swiper-parallax="-360"></div>
          <div class="txt" data-swiper-parallax="-460">
            <p>{{item.intro}}</p>
          </div>
        </div>
        <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
      </swiper-slide>
    </swiper>
    <div class="pagination">
      <div
        v-for="(item, idx) in slideList"
        :key="item.pageClass"
        class="page-item"
        :class="[item.pageClass, (idx === selectSlide ? 'active' : '')]"
        @click="handlePageClick(idx)"
      ></div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, Parallax } from 'swiper/modules'

export default {
  name: "ReserveFaWorldPage",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },

  data() {
    this.slideList = [
      {
        name: '永生空间',
        pageClass: 'rskj',
        pic: require('@/assets/img/world/pic_4.png'),
        title: require('@/assets/img/world/tit_4.png'),
        intro: '2177年，人类科学家通过"脑机接口"创造了仿生数字世界--"Ω空间"，95%的人类在服务器上实现了"永生"。但由于伦理上的争议，"Ω空间"被迫关闭;但私下里，创始人之一的莱茵哈特在不为人知的地方打造了巨大的服务器重启"Ω空间"，并将人类意识备份导入，人类的"数字文明"诞生了!"Ω空间"如同一个新世界开始蓬勃发展...'
      },
      {
        name: '天启战争',
        pageClass: 'tqzz',
        pic: require('@/assets/img/world/pic_2.png'),
        title: require('@/assets/img/world/tit_2.png'),
        intro: '文明似乎总会引来灾难，Ω空间中，来历不明的"天启"释放大量"α病毒"，"数字人类"被病毒感染、侵蚀，逐渐丧失自我意识；正当整个"Ω空间"即将被病毒侵蚀殆尽时，终端回溯被触发，所有的一切都回到初始状态；人们开始了和天启的循环之战。'
      },
      {
        name: '先驱觉醒',
        pageClass: 'xqjx',
        pic: require('@/assets/img/world/pic_3.png'),
        title: require('@/assets/img/world/tit_3.png'),
        intro: '在"α病毒"大面积碾压式的进攻下，Ω空间已经进入第101次回溯而在多次的回溯中，"数字人类"对抗α病毒的力量逐渐成型，这些拥有强大意志的战斗英雄被他人称为"先驱"。'
      },
      {
        name: '破晓觉醒',
        pageClass: 'pxjx',
        pic: require('@/assets/img/world/pic_1.png'),
        title: require('@/assets/img/world/tit_1.png'),
        intro: 'Ω空间仍在无休止地回溯，不断经历毁灭与新生，在α病毒的侵蚀中，部分感染者逐渐觉醒了自我意识，自称为"破晓"，他们似乎意识到这世界如同鸟笼，而自己并不是真实的人类....'
      },
    ]
    return {
      modules: [Autoplay, Parallax],
      selectSlide: 0,
    }
  },
  watch: {
    isActive(val) {
      if (val) {
        this.$refs.worldSlide.$el.swiper.slideTo(0)
      }
    }
  },

  mounted() {},

  methods: {
    handlePageClick(idx) {
      this.selectSlide = idx
      this.$refs.worldSlide.$el.swiper.slideTo(idx)
    },
    handleSlideChange({ activeIndex }) {
      this.selectSlide = activeIndex
    }
  },
};
</script>

<style lang="scss" scoped>
$mainColor: #32feff;
.sjg {
  padding-bottom: 0px;
  display: flex;
  align-items: center;

  .world-slide, .slide-item {
    width: 100%;
    height: 760px;
    position: relative;
  }
  .slide-img {
    width: 72%;
    height: 760px;
    position: absolute;
    right: 0;
    top: 0;
  }
  .slide-info {
    top: 47.8%;
    margin: 0 60px;
    z-index: 3;
    position: absolute;
    .tit {
      width: 626px;
      color: $mainColor;
      font-size: 60px;
      font-weight: bold;
      margin-bottom: 46px;
      &.t1 {
        width: 433px;
      }
      &.t2 {
        width: 268px;
      }
      &.t3 {
        width: 272px;
      }
      &.t4 {
        width: 272px;
      }
    }
    .line {
      width: 626px;
      height: 10px;
      background: url('@/assets/img/world/line.png') center no-repeat;
      background-size: 100%;
    }
    .txt {
      width: 562px;
      font-size: 18px;
      line-height: 32px;
      padding-left: 60px;
      padding-top: 28px;
      word-break: break-all;
    }
  }
  :deep(.swiper-pagination-bullets) {
    padding-left: 110px;
    text-align: left;
    bottom: 0;
    .swiper-pagination-bullet {
      background-color: rgba($color: #fff, $alpha: .5);
      width: 33px;
      height: 4px;
      border-radius: 0;
    }
    .swiper-pagination-bullet-active {
      background-color: $mainColor;
    }
  }
}
.pagination {
  position: absolute;
  top: 20%;
  left: 60px;
  z-index: 5;
  .page-item {
    width: 176px;
    height: 15px;
    margin-bottom: 22px;
    background-image: url('@/assets/img/world/pagination.png');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 348px 129px;
    position: relative;
    cursor: pointer;
    &::before {
      content: '\20';
      background-color: #e02858;
      width: 0px;
      height: 14px;
      position: absolute;
      left: 0px;
    }
    &.rskj {
      background-position: 0 0;
    }
    &.tqzz {
      background-position: 0 -38px;
    }
    &.xqjx {
      background-position: 0 -38 * 2px;
    }
    &.pxjx {
      background-position: 0  -38 * 3px;
    }
    &.active {
      background-position-x: -180px;
      &::before {
        width: 2px;
        left: -12px;
        transition: all .2s ease-in;
      }
    }
  }
}
@media (max-width: 1366px) {
  .sjg .slide-info {
    transform-origin: 0%;
    transform: scale(0.75);
  }
}
</style>
