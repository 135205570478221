import { createApp } from 'vue'
import App from './App.vue'
// import store from './store'
import 'element-plus/dist/index.css'

// import swiper module styles
import 'swiper/css'
// more module style...


const app = createApp(App)

app.mount('#app')
