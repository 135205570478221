<template>
  <div class="dialog">
    <div class="dialog-content animate__animated" :class="isShowContent ? 'animate__fadeInUp' : 'animate__fadeOut'">
      <!-- 信息录入 -->
      <div v-if="!roleInfo" class="dialog-body login animate__animated animate__fadeIn">
        <div class="tags">
          <div class="tag-item" :class="{active: osType === 1}" @click="osType = 1"><span>安卓</span></div>
          <div class="tag-item" :class="{active: osType === 2}" @click="osType = 2"><span>IOS</span></div>
        </div>
        <div class="ipt-item">
          <input v-model.number="phone" class="ipt" type="tel" maxlength="11" placeholder="请输入您的手机号" />
        </div>
        <div class="ipt-item">
          <input v-model="code" class="ipt" maxlength="6" type="text" placeholder="请输入短信验证码" />
          <button class="sms-btn" @click="sendSMSCode">{{ !second ? '获取验证码' : second + '秒重获' }}</button>
        </div>
        <div class="form-btn">
          <img class="login-btn" src="@/assets/img/dialog/login_btn.png" alt="完成录入" @click="login" />
        </div>
      </div>
      <!-- 角色信息 -->
      <div v-else-if="!isShare" class="dialog-body role animate__animated animate__fadeIn">
        <div class="role-img">
          <img class="img-full" :src="getBirthInfo(roleInfo.birthplace)" alt="" />
          <img class="img-line" src="@/assets/img/dialog/role_line.png" alt="">
          <img class="img-zw" src="@/assets/img/dialog/zw.png" alt="">
        </div>
        <div class="role-info">
          <div class="ipt-item">
            <label>ΩID</label>
            <input v-model="roleInfo.roleId" readonly class="ipt" type="text" placeholder="ΩId" />
          </div>
          <div class="ipt-item">
            <label>出生地</label>
            <input :value="roleInfo.birthplace" readonly class="ipt" type="text" placeholder="出生地" />
          </div>
          <div class="ipt-item">
            <label>角色名</label>
            <input v-model="roleInfo.roleName" class="ipt" maxlength="32" type="text" placeholder="请输入您的角色名" />
          </div>
          <div class="share-btn">
            <img class="img-full" src="@/assets/img/dialog/share_btn.png" alt="分享你的专属Ω身份" @click="shareRoleCard" />
          </div>
        </div>
      </div>
      <!-- 身份卡 -->
      <template v-else>
        <div ref="roleCard" class="dialog-body role">
          <div class="role-img">
            <img class="img-full" :src="getBirthInfo(roleInfo.birthplace)" alt="" />
            <img class="img-line" src="@/assets/img/dialog/share_line.png" alt="">
            <div class="oid">
              <p>{{ roleInfo.roleId }}</p>
            </div>
          </div>
          <div class="role-info">
            <div class="ipt-item">
              <label>出生地</label>
              <div class="ipt">{{ roleInfo.birthplace }}</div>
              <!-- <input :value="getBirthInfo('place', roleInfo.birthplace)" readonly class="ipt" type="text" placeholder="出生地" /> -->
            </div>
            <div class="ipt-item">
              <label>角色名</label>
              <div class="ipt">{{ roleInfo.roleName }}</div>
              <!-- <input v-model="roleInfo.roleName" readonly class="ipt" maxlength="32" type="text" placeholder="请输入您的角色名" /> -->
            </div>
            <div class="qrcode-box">
              <div class="qrcode">
                <img class="img-full" :src="qrcodeImg" alt="" />
              </div>
            </div>
          </div>
          <!-- // 保存图片 -->
          <a v-if="cardImgData" class="save-img" :href="cardImgData" download="矩阵临界分享图片.png"> </a>
        </div>
        <p class="tips" :class="{hide: !cardImgData}">
          <!-- {{ cardImgData ? '点击上面图片保存到本地' : '' }} -->
          <a class="save-img" :href="cardImgData" download="矩阵临界分享图片.png">
            <img class="tip-img" src="@/assets/img/dialog/save_txt.png" alt="点击保存图片进行分享">
          </a>
        </p>
      </template>
      <div class="close" @click="handleClose"></div>
    </div>
  </div>
</template>
<script>
import { ElMessage } from 'element-plus';
import html2canvas from 'html2canvas'
import QRCode from 'qrcode'
import Apis from '@/apis'

export default {
  name: "DialogComponent",
  emits: ["update:visit"],
  props: {
    visit: Boolean,
  },
  // emits: ['loginSuccess'],
  data() {
    this.roleInfoList = [
      {
        place: '阿特拉斯世界',
        img: require('@/assets/img/dialog/role_atls.png')
      },
      {
        place: '乌拉诺斯领域',
        img: require('@/assets/img/dialog/role_wlns.png')
      },
      {
        place: '废弃域',
        img: require('@/assets/img/dialog/role_fqy.png')
      },
      {
        place: '交叠空间',
        img: require('@/assets/img/dialog/role_jdkj.png')
      },
      {
        place: '未知领域',
        img: require('@/assets/img/dialog/role_wzly.png')
      },
    ]
    return {
      isShowContent: false,
      osType: 1,
      phone: '',
      code: '',
      isLoading: false,
      second: 0, // 倒计时秒数
      roleInfo: null,
      isShare: false,
      cardImgData: '',
      qrcodeImg: '',
    }
  },
  mounted() {
    this.roleInfo = JSON.parse(localStorage.getItem('roleInfo'))
    this.phone = this.roleInfo?.phone || ''
    this.getQrcodeImg()
    this.$nextTick(() => {
      this.isShowContent = true
    });
  },
  beforeUnmount() {
    this.clearTimer()
  },
  methods: {
    handleAfterLeave() {
      this.$emit("update:visit", false);
    },
    handleClose() {
      this.isShowContent = false;
      setTimeout(() => {
        this.handleAfterLeave();
      }, 300);
    },
    // 录入
    // 倒计时
    countDown(sec) {
      this.second = sec
      if (this.second === 0) {
        return this.clearTimer()
      }
      this.timer = setTimeout(() => {
        sec--
        this.countDown(sec)
      }, 1000)
    },
    async sendSMSCode() {
      if (this.isLoading || this.second > 0) return
      if (!this.phone) {
        return ElMessage.error('手机号不能为空')
      }
      if (!/^1\d{10}$/.test(this.phone)) {
        return ElMessage.error('请填写正确的手机号吗')
      }
      this.isLoading = true
      // 获取短信接口
      // console.log('请求接口')
      const res = await Apis.sendCode({ phone: this.phone })
      this.isLoading = false
      if (res.code) {
        return ElMessage.error(res.msg)
      }
      ElMessage.success('发送成功')
      // 设置定时器时间 60 秒
      this.countDown(60)
      
    },
    clearTimer() {
      clearTimeout(this.timer)
      this.timer = null
    },
    async login() {
      if (this.isLoading) return
      if (!this.phone) {
        return ElMessage.error('手机号不能为空')
      }
      if (!/^1\d{10}$/.test(this.phone)) {
        return ElMessage.error('请填写正确的手机号吗')
      }
      if (!this.code) {
        return ElMessage.error('验证码不能为空')
      }
      this.isLoading = true
      // 获取短信接口
      // console.log('请求接口')
      const res = await Apis.subscribe({
        phone: this.phone,
        os: this.osType === 1 ? 'android' : 'ios',
        code: this.code
      })
      this.isLoading = false
      if (res.code) {
        return ElMessage.error(res.msg)
      }
      const roleInfo = {
        phone: this.phone,
        roleId: res.data.uid,
        roleName: res.data.name,
        birthplace: res.data.from_area, // 1,2,3,4,5
      }
      localStorage.setItem('roleInfo', JSON.stringify(roleInfo))
      this.$emit('loginSuccess', this.phone)
      if (res.data.is_repeat) {
        ElMessage.success('您已经预约过了')
        return this.handleClose()
      }
      this.roleInfo = roleInfo
    },

    // 角色
    // 获取出生地
    getBirthInfo(place) {
      // type: place-出生地， img-图片
      // place 出生地id
      const info = this.roleInfoList.filter(item => item.place === place)
      return info[0] ? info[0].img : 'null'
    },
    // 分享身份卡
    getQrcodeImg() {
      QRCode.toDataURL(location.origin,  { errorCorrectionLevel: 'H', width: 144 }, (err, url) => {
        this.qrcodeImg = url
      })
    },
    async shareRoleCard() {
      if (!this.roleInfo.roleName) {
        return ElMessage.error('角色名不能为空')
      }
      // let byteLength = encodeURI(str).split(/%..|./).length - 1;
      // console.log(byteLength);
      if (!/^[a-zA-Z0-9\u4e00-\u9fa5]{0,8}$/.test(this.roleInfo.roleName)) {
        return ElMessage.error('角色名仅能使用中文，字母，数字组成，长度8个字以内')
      }
      // if (encodeURI(this.roleInfo.roleName).split(/%..|./).length - 1 > 24) {
      //   return ElMessage.error('角色名仅能使用中文，字母，数字组成，长度8个字以内2')
      // }
      const res = await Apis.addRoleName({
        phone: this.phone,
        name: this.roleInfo.roleName
      })
      if (res.code) {
        return ElMessage.error(res.msg)
      }
      localStorage.setItem('roleInfo', JSON.stringify(this.roleInfo))
      this.isShare = true
      this.$nextTick(() => {
        html2canvas(this.$refs.roleCard, { useCORS: true, backgroundColor: null }).then((canvas) => {
          // document.body.appendChild(canvas)
          this.cardImgData = canvas.toDataURL("image/png")
        });
      })
      
      
    },
  },
};
</script>

<style lang="scss" scoped>
// 切角
// clip-path: polygon(0 20px, 100% 0, 100% 100%, 0 100%);
.dialog {
  background-color: rgba($color: #000000, $alpha: 0.6);
  position: absolute;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  .dialog-content {
    position: relative;
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    .dialog-body {
      width: 623px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
      overflow: hidden;
      position: relative;
      &.login {
        padding: 78px 57px 0 50px;
        height: 455px;
        background-image: url("../../assets/img/dialog/login_bg.png");
      }
      &.role {
        background-image: url("../../assets/img/dialog/role_bg.png");
        height: 404px;
        padding: 56px 36px 0 41px;
        display: flex;
      }
      &.hide {
        display: none;
      }
      .save-img {
        opacity: 0;
        position: absolute;
        z-index: 5;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    .tips {
      font-size: 16px;
      line-height: 30px;
      height: 54px;
      text-align: center;
      margin: 0;
      color: #a9a9a9;
      text-shadow: 0 0 4px rgba($color: #000000, $alpha: .5);
      &.hide {
        visibility: hidden;
      }
      .tip-img {
        width: 324px;
        height: 54px;
      }
    }
    .close {
      width: 49px;
      height: 49px;
      background: url('@/assets/img/dialog/dialog_close.png') center center no-repeat;
      background-size: 100% 100%;
      position: absolute;
      right: -43px;
      top: 34px;
      cursor: pointer;
    }
  }
  .fade-slide-enter-active {
    transition: all 0.3s ease-in;
  }

  .fade-slide-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .fade-slide-enter-from,
  .fade-slide-leave-to {
    transform: translateY(50px);
    opacity: 0;
  }
}

.tags {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 43px;
  .tag-item {
    width: 199px;
    height: 52px;
    position: relative;
    margin: 0 24px;
    text-align: center;
    line-height: 52px;
    font-size: 30px;
    color: #32bfcf;
    > span {
      position: relative;
      z-index: 2;
    }
    &::before {
      content: '\20';
      position: absolute;
      z-index: 1;
      opacity: .6;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: url('@/assets/img/dialog/tag.png') center center no-repeat;
      background-size: 100% 100%;
    }
    &.active {
      color: #000;
      &::before {
        opacity: 1;
      }
    }
  }
}

.login {
  .ipt-item {
    display: flex;
    margin-top: 16px;
    .ipt {
      border: 2px solid #2c667b;
      height: 62px;
      line-height: 62px;
      width: 100%;
      background-color: rgba($color: #000000, $alpha: .6);
      outline: none;
      display: block;
      flex: auto;
      box-sizing: border-box;
      font-size: 24px;
      padding: 0 20px;
      color: #fff;
    }
    .sms-btn {
      box-sizing: border-box;
      width: 158px;
      height: 62px;
      display: block;
      border: 0;
      outline: none;
      background: url('@/assets/img/dialog/sms_btn.png') center center no-repeat;
      background-size: 100% 100%;
      color: #000;
      font-size: 24px;
      flex: none;
      margin-left: 10px;
      padding: 0;
      cursor: pointer;
    }
  }
  .login-btn {
    width: 480px;
    height: 116px;
    display: block;
    margin: 0 auto;
    cursor: pointer;
  }
}

.role {
  .role-img {
    width: 214px;
    height: 302px;
    flex: none;
    margin-right: 58px;
    position: relative;
    .img-line {
      width: 362px;
      height: 323px;
      position: absolute;
      top: -21px;
      left: -91px;
      z-index: 2;
    }
    .img-zw {
      width: 95px;
      height: 100px;
      position: absolute;
      z-index: 3;
      bottom: 12px;
      right: -47px;
    }
    .oid {
      width: 176px;
      height: 157px;
      background: url('@/assets/img/dialog/id.png') center center no-repeat;
      background-size: 100% 100%;
      position: absolute;
      bottom: -24px;
      right: -10px;
      z-index: 3;
      > p {
        text-align: center;
        color: #2feeff;
        font-size: 24px;
        line-height: 157px;
        transform: rotate(-14deg);
        font-weight: bold;
      }
    }
  }
  .role-info {
    width: 275px;
    .ipt-item {
      display: flex;
      width: 275px;
      height: 52px;
      background: url('@/assets/img/dialog/ipt_bg.png') left top no-repeat;
      background-size: 100% 100%;
      margin-bottom: 18px;
      > label {
        width: 90px;
        flex: none;
        font-size: 24px;
        color: #2feeff;
        text-align: center;
        line-height: 52px;
      }
      .ipt {
        background: none;
        border: none;
        display: block;
        box-sizing: border-box;
        width: 184px;
        font-size: 24px;
        line-height: 52px;
        color: #fff;
        text-align: center;
        outline: none;
        &::placeholder, &::-webkit-input-placeholder {
          color: rgba($color: #ffffff, $alpha: .2);
          font-size: 20px;
        }
      }
    }
    .share-btn {
      width: 480px;
      height: 116px;
      margin: 13px 0 0 -107px;
      cursor: pointer;
    }
    .qrcode-box {
      width: 295px;
      margin-top: 25px;
      background: url('@/assets/img/dialog/share_icon.png') right center no-repeat;
      background-size: 112px 156px;
      .qrcode {
        width: 144px;
        height: 144px;
        padding: 2px 44px 2px 2px;
        background: url('@/assets/img/dialog/qrcode_bg.png') center center no-repeat;
        background-size: 100% 100%;
        > img {
          background-color: #fff;
        }
      }
    }
  }
}
</style>
