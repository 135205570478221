<template>
  <div class="sldt">
    <div class="bg-video">
      <video
        preload="auto"
        autoplay="true"
        muted="true"
        loop="true"
        webkit-playsinline="true"
        playsinline="true"
        x5-playsinline="true"
        x-webkit-airplay="allow"
      >
        <source src="../../assets/img/area/bg.mp4"  type="video/mp4">
      </video>
    </div>
    <!-- 地标 -->
    <div v-for="(item) in slideList" :key="item.className" class="place-item" :class="item.className">
      <div class="target">
        <img class="img-full" src="@/assets/img/area/target.png" alt="" />
      </div>
      <div class="area-name" @click="introShowState[item.className] = !introShowState[item.className]">
        <img class="img-full" :src="item.title" :alt="item.name" />
      </div>
      <div v-show="introShowState[item.className]" class="area-intro animate__animated" :class="{'animate__fadeIn': introShowState[item.className] }">{{ item.intro }}</div>
    </div>
    <!-- 滑动提示 -->
    <!-- <div v-if="isShowTips" class="hand-tips">
      <div class="hand"></div>
      <p class="txt">左右拖动屏幕</p>
    </div> -->
  </div>
</template>
<script>


export default {
  name: "ReserveFaAreaPage",
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    this.slideList = [
      {
        name: '破晓基地',
        className: 'pxjd',
        title: require('@/assets/img/area/t1.png'),
        intro: '感染了“α病毒”却仍能维持自我意识的人类所成立的组织，自称“破晓者”，与先驱理事会对立，口号为“自由、觉醒”'
      },
      {
        name: '乌拉诺斯领域',
        className: 'wlnsly',
        title: require('@/assets/img/area/t2.png'),
        intro: '“α病毒”的发源地，通过此处侵入交叠空间，企图完全占据整个世界，但其中可能埋藏着“Ω”起源的秘密。'
      },
      {
        name: '铁壁',
        className: 'tb',
        title: require('@/assets/img/area/t3.png'),
        intro: '先驱领袖芬里尔为了抵抗“α病毒”侵蚀世界，建造了巨大的屏障保护天幕城，人们称之为“铁壁”，据数据测试，突破铁壁至少花费上百年的时间。'
      },
      {
        name: '先驱理事会',
        className: 'xqlsh',
        title: require('@/assets/img/area/t4.png'),
        intro: '先驱是阿特拉斯世界的保卫者，意志强大的英雄，他们通过改造义体实现进化，清除“α病毒”及其感染者，此处是交叠空间中的先驱理事会。'
      },
    ]
    return {
      isShowTips: true,
      introShowState: {
        pxjd: true,
        wlnsly: true,
        tb: true,
        xqlsh: true,
      }
    };
  },
  watch: {
    isActive(val) {
      if (val) {
        this.introShowState = {
          pxjd: true,
          wlnsly: true,
          tb: true,
          xqlsh: true,
        }
      }
    }
  },
  mounted() {},

  methods: {
  },
};
</script>

<style lang="scss" scoped>
$mainColor: #32feff;
.sldt {
  height: 100%;
  width: 100%;
  overflow: hidden;
  .place-item {
    position: absolute;
    transform-origin: 0% 0%;
    // top: 50%;
    // left: 50%;
    z-index: 3;
    &.pxjd {
      top: 56%;
      left: 11%;
      // margin-left: -720px;
      // margin-top: 140px;
    }
    &.wlnsly {
      top: 46%;
      left: 32%;
      // margin-left: -320px;
      // margin-top: -30px;
    }
    &.tb {
      top: 46%;
      left: 57%;
      // margin-left: 150px;
      // margin-top: -30px;
    }
    &.xqlsh {
      top: 42%;
      left: 76%;
      // margin-left: 512px;
      // margin-top: -80px;
    }
    .target {
      width: 112px;
      height: 111px;
    }
    .area-name {
      width: 298px;
      height: 71px;
      margin-top:  -42px;
      margin-left: 72px;
      cursor: pointer;
    }
    .area-intro {
      width: 269px;
      font-size: 14px;
      box-sizing: border-box;
      padding: 22px 12px 32px;
      line-height: 1.5;
      color: #000;
      background: url('@/assets/img/area/txt_bg.png') center top no-repeat;
      background-size: 100% 100%;
      margin-top: -5px;
      margin-left: 97px;
      animation-duration: .8s;
      // display: none;
    }
  }
  
  .bg-video {
    overflow: hidden;
    > video {
      object-fit: fill;
    }
  }
}
@media (max-width: 1550px) {
  .sldt {
    .place-item {
      transform: scale(.8);
    }
  }
}
@media (max-width: 1366px) {
  .sldt {
    .place-item {
      transform: scale(.7);
    }
  }
}
</style>
