<template>
  <div>
    <div class="music" :class="isPlay ? 'play' : 'pause'" @click="taggleStatus()"></div>
    <audio ref="bgmElem" class="bgm" preload="auto" loop>
      <source src="@/assets/audio/bgm.wav" type="audio/wav">
    </audio>
  </div>
</template>

<script>
export default {
  name: 'BgAudio',
  data() {
    return {
      isPlay: 0, // 0 暂停中  1 播放中
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.isPlay = this.$refs.bgmElem.paused ? 0 : 1
    })
  },
  methods: {
    taggleStatus() {
      // this.isPlay = Number(!this.isPlay)
      if (this.isPlay) {
        this.isPlay = 0
        this.$refs.bgmElem.pause()
      } else {
        this.isPlay = 1
        this.$refs.bgmElem.play()
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@keyframes btnRotate {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.music {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 36px;
  height: 36px;
  margin-right: 10px;
  cursor: pointer;
  flex: none;
  &.play {
    background-image: url('@/assets/img/music_play.png');
    animation: btnRotate 3s linear infinite;
  }
  &.pause {
    background-image: url('@/assets/img/music_pause.png');
  }
}
.bgm {
  display: none;
}
</style>